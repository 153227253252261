.Background {
  background-color: #282c34 !important;
  color: #282c34;
  text-align: center;
  min-height: 100vh;
  display: flex;
  overflow: overlay; 
  overflow-y: hidden;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Game-List{
  flex: 100%;
  background-color: #282c34;
  color: white;
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: xx-large;
}

.Game-View{
  width: 100%;
  padding-top: 40px;
  padding-bottom: 120px;
}

.PlayerStats-View{
  flex: 100%;
  width: 100%;
  background-color: #282c34;
  color: white;
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: x-large;
}

.MenuBar-Header{
  background-color: #282c34 !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  color: white;
}

.BurgerMenu-Button{
  position: fixed;
  left: 5px;
  top: 5px;
  background-image: url('./components/resources/BurgerMenu.png');
  background-size: 25px;
  background-color: #282c34;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  float:left;
}

.MenuBar-BottomLine{
  position: fixed;
  top: 40px;
  height: 1px;
  width: 100%;
  background-color: #ffffff;
}

.MenuBar-Text{
  position: relative;
  top: 2px;
  font-size: 28px;
}

.AddShot-Footer{
  background-color: #282c34 !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.AddShot_TopLine{
  position: fixed;
  bottom: 80px;
  height: 1px;
  width: 100%;
  background-color: #ffffff;
}

.GameItem-Button{
  height: 60px;
  width: 95%;
  font-size: large;
  margin: 5px;
}

.PlayerItem-Button{
  height: 40px;
  font-size: medium;
  margin: 5px;
  float: left;
}

.PlayerItem-Button-Selected{
  height: 40px;
  font-size: medium;
  margin: 5px;
  float: left;
  background-color: #0069d9;
}

.NewGame-Button{
  height: 60px;
  width: 95%;
  font-size: large;
  background-color: orange;
  margin: 5px;
}

.Back-Button{
  background-image: url('./components/resources/back.svg');
  height: 50px;
  width: 50px;
  border-width: 0px;
  float: left;
}

.HeaderImage{
  background-image: url('./components/resources/icon128.png');
  background-size: 32px;
  height: 32px;
  width: 32px;
  position: absolute;
  left: 100px;
  top: 4px;
  
}

.DropDown-Background{
  background-color: #525968;
  margin-top: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
}

table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid black;
  width: 100%
}

td {
  border: 1px solid black;
  width: 16.6%
}

h1{
  font-size: xx-large;
}

h2{
  font-size: x-large;
}

label{
  font-size: large;
}

#NewGameForm:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: -2;
}

#NewGameForm:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #FFF;
  z-index: -1;
}

.popup {
  display: none;
  position: fixed;
  padding: 10px;
  width: 100%;
  left: 0%;
  margin-left: 0px;
  height: 100%;
  top: 0%;
  margin-top: 0px;
  background: #FFF;
  z-index: 20;
  overflow-y: scroll
}

.wrapper{
  display: inline-flex;
  background: #fff;
  height: 60px;
  width: 95%;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 10px 10px;
  box-shadow: 5px 5px 30px rgba(0,0,0,0.2);
}
.wrapper .option{
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 5px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot{
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before{
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"]{
  display: none;
}
#option-0:checked:checked ~ .option-0,
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4,
#option-5:checked:checked ~ .option-5,
#option-6:checked:checked ~ .option-6,
#option-7:checked:checked ~ .option-7,
#option-8:checked:checked ~ .option-8,
#option-9:checked:checked ~ .option-9,
#bullseye-0:checked:checked ~ .bullseye-0,
#bullseye-1:checked:checked ~ .bullseye-1,
#bullseye-2:checked:checked ~ .bullseye-2,
#bullseye-3:checked:checked ~ .bullseye-3,
#bullseye-4:checked:checked ~ .bullseye-4,
#bullseye-5:checked:checked ~ .bullseye-5,
#bullseye-6:checked:checked ~ .bullseye-6,
#bullseye-7:checked:checked ~ .bullseye-7,
#bullseye-8:checked:checked ~ .bullseye-8,
#bullseye-9:checked:checked ~ .bullseye-9,
#option-0-edit:checked:checked ~ .option-0-edit,
#option-1-edit:checked:checked ~ .option-1-edit,
#option-2-edit:checked:checked ~ .option-2-edit,
#option-3-edit:checked:checked ~ .option-3-edit,
#option-4-edit:checked:checked ~ .option-4-edit,
#option-5-edit:checked:checked ~ .option-5-edit,
#option-6-edit:checked:checked ~ .option-6-edit,
#option-7-edit:checked:checked ~ .option-7-edit,
#option-8-edit:checked:checked ~ .option-8-edit,
#option-9-edit:checked:checked ~ .option-9-edit,
#bullseye-0-edit:checked:checked ~ .bullseye-0-edit,
#bullseye-1-edit:checked:checked ~ .bullseye-1-edit,
#bullseye-2-edit:checked:checked ~ .bullseye-2-edit,
#bullseye-3-edit:checked:checked ~ .bullseye-3-edit,
#bullseye-4-edit:checked:checked ~ .bullseye-4-edit,
#bullseye-5-edit:checked:checked ~ .bullseye-5-edit,
#bullseye-6-edit:checked:checked ~ .bullseye-6-edit,
#bullseye-7-edit:checked:checked ~ .bullseye-7-edit,
#bullseye-8-edit:checked:checked ~ .bullseye-8-edit,
#bullseye-9-edit:checked:checked ~ .bullseye-9-edit{
  border-color: #0069d9;
  background: #0069d9;
}
#option-0:checked:checked ~ .option-0 .dot,
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot,
#option-4:checked:checked ~ .option-4 .dot,
#option-5:checked:checked ~ .option-5 .dot,
#option-6:checked:checked ~ .option-6 .dot,
#option-7:checked:checked ~ .option-7 .dot,
#option-8:checked:checked ~ .option-8 .dot,
#option-9:checked:checked ~ .option-9 .dot,
#bullseye-0:checked:checked ~ .bullseye-0 .dot,
#bullseye-1:checked:checked ~ .bullseye-1 .dot,
#bullseye-2:checked:checked ~ .bullseye-2 .dot,
#bullseye-3:checked:checked ~ .bullseye-3 .dot,
#bullseye-4:checked:checked ~ .bullseye-4 .dot,
#bullseye-5:checked:checked ~ .bullseye-5 .dot,
#bullseye-6:checked:checked ~ .bullseye-6 .dot,
#bullseye-7:checked:checked ~ .bullseye-7 .dot,
#bullseye-8:checked:checked ~ .bullseye-8 .dot,
#bullseye-9:checked:checked ~ .bullseye-9 .dot,
#option-0-edit:checked:checked ~ .option-0-edit .dot,
#option-1-edit:checked:checked ~ .option-1-edit .dot,
#option-2-edit:checked:checked ~ .option-2-edit .dot,
#option-3-edit:checked:checked ~ .option-3-edit .dot,
#option-4-edit:checked:checked ~ .option-4-edit .dot,
#option-5-edit:checked:checked ~ .option-5-edit .dot,
#option-6-edit:checked:checked ~ .option-6-edit .dot,
#option-7-edit:checked:checked ~ .option-7-edit .dot,
#option-8-edit:checked:checked ~ .option-8-edit .dot,
#option-9-edit:checked:checked ~ .option-9-edit .dot,
#bullseye-0-edit:checked:checked ~ .bullseye-0-edit .dot,
#bullseye-1-edit:checked:checked ~ .bullseye-1-edit .dot,
#bullseye-2-edit:checked:checked ~ .bullseye-2-edit .dot,
#bullseye-3-edit:checked:checked ~ .bullseye-3-edit .dot,
#bullseye-4-edit:checked:checked ~ .bullseye-4-edit .dot,
#bullseye-5-edit:checked:checked ~ .bullseye-5-edit .dot,
#bullseye-6-edit:checked:checked ~ .bullseye-6-edit .dot,
#bullseye-7-edit:checked:checked ~ .bullseye-7-edit .dot,
#bullseye-8-edit:checked:checked ~ .bullseye-8-edit .dot,
#bullseye-9-edit:checked:checked ~ .bullseye-9-edit .dot{
  background: #fff;
}
#option-0:checked:checked ~ .option-0 .dot::before,
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-3:checked:checked ~ .option-3 .dot::before,
#option-4:checked:checked ~ .option-4 .dot::before,
#option-5:checked:checked ~ .option-5 .dot::before,
#option-6:checked:checked ~ .option-6 .dot::before,
#option-7:checked:checked ~ .option-7 .dot::before,
#option-8:checked:checked ~ .option-8 .dot::before,
#option-9:checked:checked ~ .option-9 .dot::before,
#bullseye-0:checked:checked ~ .bullseye-0 .dot::before,
#bullseye-1:checked:checked ~ .bullseye-1 .dot::before,
#bullseye-2:checked:checked ~ .bullseye-2 .dot::before,
#bullseye-3:checked:checked ~ .bullseye-3 .dot::before,
#bullseye-4:checked:checked ~ .bullseye-4 .dot::before,
#bullseye-5:checked:checked ~ .bullseye-5 .dot::before,
#bullseye-6:checked:checked ~ .bullseye-6 .dot::before,
#bullseye-7:checked:checked ~ .bullseye-7 .dot::before,
#bullseye-8:checked:checked ~ .bullseye-8 .dot::before,
#bullseye-9:checked:checked ~ .bullseye-9 .dot::before,
#option-0-edit:checked:checked ~ .option-0-edit .dot::before,
#option-1-edit:checked:checked ~ .option-1-edit .dot::before,
#option-2-edit:checked:checked ~ .option-2-edit .dot::before,
#option-3-edit:checked:checked ~ .option-3-edit .dot::before,
#option-4-edit:checked:checked ~ .option-4-edit .dot::before,
#option-5-edit:checked:checked ~ .option-5-edit .dot::before,
#option-6-edit:checked:checked ~ .option-6-edit .dot::before,
#option-7-edit:checked:checked ~ .option-7-edit .dot::before,
#option-8-edit:checked:checked ~ .option-8-edit .dot::before,
#option-9-edit:checked:checked ~ .option-9-edit .dot::before,
#bullseye-0-edit:checked:checked ~ .bullseye-0-edit .dot::before,
#bullseye-1-edit:checked:checked ~ .bullseye-1-edit .dot::before,
#bullseye-2-edit:checked:checked ~ .bullseye-2-edit .dot::before,
#bullseye-3-edit:checked:checked ~ .bullseye-3-edit .dot::before,
#bullseye-4-edit:checked:checked ~ .bullseye-4-edit .dot::before,
#bullseye-5-edit:checked:checked ~ .bullseye-5-edit .dot::before,
#bullseye-6-edit:checked:checked ~ .bullseye-6-edit .dot::before,
#bullseye-7-edit:checked:checked ~ .bullseye-7-edit .dot::before,
#bullseye-8-edit:checked:checked ~ .bullseye-8-edit .dot::before,
#bullseye-9-edit:checked:checked ~ .bullseye-9-edit .dot::before{
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span{
  font-size: 20px;
  color: #808080;
}
#option-0:checked:checked ~ .option-0 span,
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span,
#option-3:checked:checked ~ .option-3 span,
#option-4:checked:checked ~ .option-4 span,
#option-5:checked:checked ~ .option-5 span,
#option-6:checked:checked ~ .option-6 span,
#option-7:checked:checked ~ .option-7 span,
#option-8:checked:checked ~ .option-8 span,
#option-9:checked:checked ~ .option-9 span,
#bullseye-0:checked:checked ~ .bullseye-0 span,
#bullseye-1:checked:checked ~ .bullseye-1 span,
#bullseye-2:checked:checked ~ .bullseye-2 span,
#bullseye-3:checked:checked ~ .bullseye-3 span,
#bullseye-4:checked:checked ~ .bullseye-4 span,
#bullseye-5:checked:checked ~ .bullseye-5 span,
#bullseye-6:checked:checked ~ .bullseye-6 span,
#bullseye-7:checked:checked ~ .bullseye-7 span,
#bullseye-8:checked:checked ~ .bullseye-8 span,
#bullseye-9:checked:checked ~ .bullseye-9 span,
#option-0-edit:checked:checked ~ .option-0-edit span,
#option-1-edit:checked:checked ~ .option-1-edit span,
#option-2-edit:checked:checked ~ .option-2-edit span,
#option-3-edit:checked:checked ~ .option-3-edit span,
#option-4-edit:checked:checked ~ .option-4-edit span,
#option-5-edit:checked:checked ~ .option-5-edit span,
#option-6-edit:checked:checked ~ .option-6-edit span,
#option-7-edit:checked:checked ~ .option-7-edit span,
#option-8-edit:checked:checked ~ .option-8-edit span,
#option-9-edit:checked:checked ~ .option-9-edit span,
#bullseye-0-edit:checked:checked ~ .bullseye-0-edit span,
#bullseye-1-edit:checked:checked ~ .bullseye-1-edit span,
#bullseye-2-edit:checked:checked ~ .bullseye-2-edit span,
#bullseye-3-edit:checked:checked ~ .bullseye-3-edit span,
#bullseye-4-edit:checked:checked ~ .bullseye-4-edit span,
#bullseye-5-edit:checked:checked ~ .bullseye-5-edit span,
#bullseye-6-edit:checked:checked ~ .bullseye-6-edit span,
#bullseye-7-edit:checked:checked ~ .bullseye-7-edit span,
#bullseye-8-edit:checked:checked ~ .bullseye-8-edit span,
#bullseye-9-edit:checked:checked ~ .bullseye-9-edit span{
  color: #fff;
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.shake-horizontal {
	-webkit-animation: shake-horizontal 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-horizontal 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
